jQuery(function() {
    // Funktion zur Überprüfung des Scrollverhaltens und Anzeige des "Scroll to Top"-Buttons
    var checkScroll = function () {
        var offset = 50;
        if ($(window).scrollTop() > offset) {
            $('.fa-arrow-up').fadeIn();
            $('.progress-wrap').addClass('active-progress');
            $('.progress-wrap').removeClass('hidden');
        } else {
            $('.fa-arrow-up').fadeOut();
            $('.progress-wrap').removeClass('active-progress');
            $('.progress-wrap').addClass('hidden');
        }
    };

    // Aufrufen der checkScroll-Funktion beim Laden der Seite
    checkScroll();

    // Aufrufen der checkScroll-Funktion beim Scrollen
    $(window).on('scroll', function() {
        checkScroll();
    });

    // Funktion zur Aktualisierung des Fortschrittsbalkens basierend auf dem Scrollen
    var updateProgress = function () {
        var scroll = $(window).scrollTop();
        var height = $(document).height() - $(window).height();
        var progress = scroll * 100 / height; // Berechnung des Fortschritts in Prozent
        $('.progress-circle-bg').css('stroke-dashoffset', 307.919 - (progress * 307.919 / 100)); // Anpassung des Fortschrittsbalkens
    };

    // Aufrufen der updateProgress-Funktion beim Laden der Seite und beim Scrollen
    $(window).on('load scroll', function() {
        updateProgress(); // Fortschrittsbalken aktualisieren
    });

    // Scroll-to-Top-Funktion beim Klicken auf den Fortschrittsbalken
    $('.progress-wrap').on('click', function(event) {
        event.preventDefault();
        $('html, body').animate({scrollTop: 0}, 550);
        return false;
    });
});


jQuery(function() {
    // Funktion zur Überprüfung des Scrollverhaltens und Anzeige des "Scroll to Top"-Buttons
    $(window).on('scroll', function() {
        var offset = 50;
        if ($(this).scrollTop() > offset) {
            $('.fa-arrow-up').fadeIn();
        } else {
            $('.fa-arrow-up').fadeOut();
        }
    });

    // Initialisierung des Fortschrittsbalkens und Überwachung des Scrollverhaltens
    var progressPath = document.querySelector('.progress-wrap path');
    var pathLength = progressPath.getTotalLength();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
    progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
    progressPath.style.strokeDashoffset = pathLength;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

    var updateProgress = function () {
        var scroll = $(window).scrollTop();
        var height = $(document).height() - $(window).height();
        var progress = pathLength - (scroll * pathLength / height);
        progressPath.style.strokeDashoffset = progress;
    }
    updateProgress();
    $(window).scroll(updateProgress);

    // Überwachung des Scrollverhaltens und Anzeige des Fortschrittsbalkens
    var offset = 50;
    var duration = 550;
    jQuery(window).on('scroll', function() {
        if (jQuery(this).scrollTop() > offset) {
            jQuery('.progress-wrap').addClass('active-progress');
            jQuery('.progress-wrap').removeClass('hidden');
        } else {
            jQuery('.progress-wrap').removeClass('active-progress');
            jQuery('.progress-wrap').addClass('hidden');
        }
    });

    // Scroll-to-Top-Funktion beim Klicken auf den Fortschrittsbalken
    jQuery('.progress-wrap').on('click', function(event) {
        event.preventDefault();
        jQuery('html, body').animate({scrollTop: 0}, duration);
        return false;
    });
});
