

var registerRoute;
var checkEmailRoute;

jQuery(function ($) {


    // Ajax Syntax für routes ? warum data:
    registerRoute = $('section[data-register-route]').data('register-route');
    checkEmailAvailabilityRoute = $('section[data-check-email-availability-route]').data('check-email-availability-route');


// noch register button deaktivieren wenn die werte nicht alle da sind

$('#email').on('blur', function () {
        var email = $('#email').val();
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test(email)) {
            $('#error_email').html('<label class="text-red-500">Invalid Email</label>');
            return;
        }

        var csrfToken = $('meta[name="csrf-token"]').attr('content'); // attr content notwendig

        $.ajax({
            url: checkEmailAvailabilityRoute,
            method: "POST",
            data: {
                "_token": csrfToken,
                "email": $("#register-form #email").val()
                        },
            success: function (result) {
                var icon = $('.input-icons .icon');
                    if (result == 'unique') {
                        icon.css('color', 'lightgreen').attr('title', 'Email existiert noch nicht');
                    } else {
                        icon.css('color', 'red').attr('title', 'Email existiert bereits');
                    }
            }
        });
    });


});
