document.addEventListener('DOMContentLoaded', () => {
    const checkbox = document.querySelector('input[name="light-switch"]'); // Das ist das Checkbox-Element aus deinem HTML

    function saveThemePreference(theme) {
        const isThemeCookieEnabled = localStorage.getItem('togglecookiethemeState') === 'true';
        if (isThemeCookieEnabled) {
            localStorage.setItem('theme', theme);
            sessionStorage.removeItem('theme');
        } else {
            sessionStorage.setItem('theme', theme);
            localStorage.removeItem('theme');
        }
    }

    function applySavedTheme() {
        const isThemeCookieEnabled = localStorage.getItem('togglecookiethemeState') === 'true';
        const savedTheme = isThemeCookieEnabled ? localStorage.getItem('theme') : sessionStorage.getItem('theme');

        if (savedTheme) {
            document.documentElement.classList.add(savedTheme);
            checkbox.checked = savedTheme === 'dark';
        } else {
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            const initialTheme = prefersDarkMode ? 'dark' : 'light';
            document.documentElement.classList.add(initialTheme);
            checkbox.checked = prefersDarkMode;
        }
    }

    function adjustThemeIconsAndSVG() {
        const isDarkMode = document.documentElement.classList.contains('dark');
        const moonsvg = document.querySelector('.moonsvg');
        const sunsvg = document.querySelector('.sunsvg');
        const themeToggleBtn = document.querySelector('.theme-switch-wrapper');

        if (isDarkMode) {
            moonsvg.style.marginTop = '0px';
            sunsvg.style.marginTop = '40px';
         } else {
            sunsvg.style.marginTop = '0px';
            moonsvg.style.marginTop = '40px';
         }
    }

    function toggleTheme() {
        const isDarkMode = document.documentElement.classList.contains('dark');
        const theme = isDarkMode ? 'light' : 'dark';

        document.documentElement.classList.toggle('dark');
        checkbox.checked = !isDarkMode;

        saveThemePreference(theme);
        adjustThemeIconsAndSVG();
    }

    function clearStorage() {
        const isThemeCookieEnabled = localStorage.getItem('togglecookiethemeState') === 'true';
        if (isThemeCookieEnabled) {
            sessionStorage.removeItem('theme');
        } else {
            localStorage.removeItem('theme');
        }
    }

    function handleCookieSettingsChange() {
        clearStorage();
        applySavedTheme();
    }



    function adjustCometColors() {
        const cometContainer = document.querySelector('.eggcontainer');

        // Überprüfe, ob cometContainer existiert, bevor du weitermachst
        if (!cometContainer) {
            return; // Beende die Funktion, wenn cometContainer null ist
        }

        const comet = cometContainer.querySelector('.egg');
        const cometface = comet.querySelector('.face');
        const comettrail = document.querySelectorAll('.egg li');
        const isDarkMode = document.documentElement.classList.contains('dark');

        comet.classList.remove('bright-comet', 'dark-comet');
        cometface.classList.remove('bright-face', 'dark-face');
        comettrail.forEach(element => {
            element.classList.remove('brighttrail', 'darktrail');
        });

        if (isDarkMode) {
            comet.classList.add('dark-comet');
            cometface.classList.add('dark-face');
            comettrail.forEach(element => element.classList.add('darktrail'));
        } else {
            comet.classList.add('bright-comet');
            cometface.classList.add('bright-face');
            comettrail.forEach(element => element.classList.add('brighttrail'));
        }
    }



    checkbox.addEventListener('change', () => {  // Wir hören auf das 'change' Event der Checkbox
        toggleTheme();
    });


    /* loest einen bug aus wenn ich auf anderen seiten bin wo dies nicht dargestellt wird

    function adjustCometColors() {
        const cometContainer = document.querySelector('.eggcontainer');
        const comet = cometContainer.querySelector('.egg');
        const cometface = comet.querySelector('.face');
        const comettrail = document.querySelectorAll('.egg li');
        const isDarkMode = document.documentElement.classList.contains('dark');

        comet.classList.remove('bright-comet', 'dark-comet');
        cometface.classList.remove('bright-face', 'dark-face');
        comettrail.forEach(element => {
            element.classList.remove('brighttrail', 'darktrail');
        });

        if (isDarkMode) {
            comet.classList.add('dark-comet');
            cometface.classList.add('dark-face');
            comettrail.forEach(element => element.classList.add('darktrail'));
        } else {
            comet.classList.add('bright-comet');
            cometface.classList.add('bright-face');
            comettrail.forEach(element => element.classList.add('brighttrail'));
        }
    }

    */

    function adjustLineColors() {
        const linespan2 = document.querySelector('.span2');
        const linespan3 = document.querySelector('.span3');
        const isDarkMode = document.documentElement.classList.contains('dark');

        linespan2.classList.remove('bright-span2', 'dark-span2');
        linespan3.classList.remove('bright-span3', 'dark-span3');

        if (isDarkMode) {
            linespan2.classList.add('dark-span2');
            linespan3.classList.add('dark-span3');
        } else {
            linespan2.classList.add('bright-span2');
            linespan3.classList.add('bright-span3');
        }
    }

    // Initialisierung des Themas
    applySavedTheme();

    adjustCometColors();
    adjustLineColors();
    adjustThemeIconsAndSVG();

    // Event-Listener für Theme-Toggle

document.querySelector('#cookieSettingsBtn').addEventListener('click', handleCookieSettingsChange);

    // Event-Listener für Cookie-Einstellungen



});
