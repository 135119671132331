import './login.js';
import './scrolltotop.js';
import './darkbrighttoggle';
import './lang.js';
import './alerts.js';
import './register.js';




console.log('Script is loaded');
