document.addEventListener('DOMContentLoaded', () => {
    const checkbox = document.querySelector('input[name="light-switch"]'); // Checkbox-Element

    function saveThemePreference(theme) {
        const isThemeCookieEnabled = localStorage.getItem('togglecookiethemeState') === 'true';
        if (isThemeCookieEnabled) {
            localStorage.setItem('theme', theme);
            sessionStorage.removeItem('theme');
        } else {
            sessionStorage.setItem('theme', theme);
            localStorage.removeItem('theme');
        }
    }

    function applySavedTheme() {
        const isThemeCookieEnabled = localStorage.getItem('togglecookiethemeState') === 'true';
        const savedTheme = isThemeCookieEnabled ? localStorage.getItem('theme') : sessionStorage.getItem('theme');

        if (savedTheme) {
            document.documentElement.setAttribute('data-theme', savedTheme);
            checkbox.checked = savedTheme === 'dark';
        }
    }

    function adjustThemeIconsAndSVG() {
        const moonsvg = document.querySelector('.moonsvg');
        const sunsvg = document.querySelector('.sunsvg');

        if (!moonsvg || !sunsvg) return; // Nur ausführen, wenn beide SVGs existieren

        const isDarkMode = document.documentElement.classList.contains('dark');
        if (isDarkMode) {
            moonsvg.style.marginTop = '0px';
            sunsvg.style.marginTop = '40px';
        } else {
            sunsvg.style.marginTop = '0px';
            moonsvg.style.marginTop = '40px';
        }
    }

    function toggleTheme() {
        const isDarkMode = document.documentElement.classList.contains('dark');
        const theme = isDarkMode ? 'light' : 'dark';

        document.documentElement.classList.toggle('dark');
        saveThemePreference(theme);
        adjustThemeIconsAndSVG();
        adjustCometColors();
        adjustLineColors();
    }

    function clearStorage() {
        const isThemeCookieEnabled = localStorage.getItem('togglecookiethemeState') === 'true';
        if (isThemeCookieEnabled) {
            sessionStorage.removeItem('theme');
        } else {
            localStorage.removeItem('theme');
        }
    }

    function handleCookieSettingsChange() {
        clearStorage();
        applySavedTheme();
    }

    function adjustCometColors() {
        const cometContainer = document.querySelector('.eggcontainer');
        if (!cometContainer) return; // Abbrechen, wenn die Elemente nicht existieren

        const comet = cometContainer.querySelector('.egg');
        const cometface = comet.querySelector('.face');
        const comettrail = document.querySelectorAll('.egg li');
        const isDarkMode = document.documentElement.classList.contains('dark');

        if (comet && cometface) {
            comet.classList.toggle('dark-comet', isDarkMode);
            comet.classList.toggle('bright-comet', !isDarkMode);

            cometface.classList.toggle('dark-face', isDarkMode);
            cometface.classList.toggle('bright-face', !isDarkMode);

            comettrail.forEach(element => {
                element.classList.toggle('darktrail', isDarkMode);
                element.classList.toggle('brighttrail', !isDarkMode);
            });
        }
    }

    function adjustLineColors() {
        const linespan2 = document.querySelector('.span2');
        const linespan3 = document.querySelector('.span3');
        if (!linespan2 || !linespan3) return; // Nur ausführen, wenn beide Elemente existieren

        const isDarkMode = document.documentElement.classList.contains('dark');
        linespan2.classList.toggle('dark-span2', isDarkMode);
        linespan2.classList.toggle('bright-span2', !isDarkMode);

        linespan3.classList.toggle('dark-span3', isDarkMode);
        linespan3.classList.toggle('bright-span3', !isDarkMode);
    }

    // Event-Listener für die Checkbox
    if (checkbox) {
        const currentRoute = window.location.pathname; // Ermittelt die aktuelle URL

        // Liste der Routen, bei denen die Checkbox deaktiviert werden soll
        const routes = ['/canvas', '/canvasart', '/canvasbackgrounds', '/canvasforms', '/canvasinteractive', '/canvasweather', '/canvasinterbackgrounds'];

        // Überprüfen, ob die aktuelle URL mit einer der Routen übereinstimmt
        if (routes.some(route => currentRoute.includes(route))) {
            checkbox.addEventListener('click', (event) => {
                event.preventDefault(); // Verhindert das Umschalten
            });
        } else {
            checkbox.addEventListener('change', toggleTheme);
        }
    }


    // Initialisierung
    applySavedTheme();
    adjustThemeIconsAndSVG();
    adjustCometColors();
    adjustLineColors();

    // Event-Listener für Cookie-Einstellungen
    const cookieSettingsBtn = document.querySelector('#cookieSettingsBtn');
    if (cookieSettingsBtn) {
        cookieSettingsBtn.addEventListener('click', handleCookieSettingsChange);
    }
});
